import React from 'react';

interface CardProps {
    title: string;
    children: React.ReactNode;
    columns?: number;
}

const FieldSectionCard: React.FC<CardProps> = ({ title, children, columns = 1 }) => {
    const gridCols = `grid-cols-1 sm:grid-cols-${Math.min(columns, 2)} md:grid-cols-${columns} lg:grid-cols-${columns}`;

    return (
        <div className="bg-white shadow-md rounded-md mb-2">
            <h3 className="text-lg font-semibold border-b px-3 py-2 bg-secondary text-white">{title}</h3>
            <div className={`grid ${gridCols} gap-4 p-4`}>
                {children}
            </div>
        </div>
    );
};

export default FieldSectionCard;
