import React, { useState } from "react";
import FieldSectionCard from "../../FieldSectionCard";
import FormField from "../../FormField";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import { useGetQuery } from "../../../redux/apis";
import LoadingSpinner from "../../LoadingSpinner";
import { useSelector } from "react-redux";
import { IoIosWarning } from "react-icons/io";
interface ModemDiagnosticInformationProps {
  rogersAccountNumber: string;
  modemMacAddress: string;
  isMainScreen?: boolean;
}

const ModemDiagnosticInformation: React.FC<any> = ({
  isMainScreen = false,
}) => {
  const customer = useSelector((state: any) => state.customer.customer);

  const [isVisible, setIsVisible] = useState(true);

  const modemMacAddress = customer.modem?.modemMac;
  const rogersAccountNumber = customer.person?.rogersAccountNum;

  const {
    data = {},
    isError,
    isLoading,
  } = useGetQuery({
    actionName: "modemdiagnostic",
    transactionData: { rogersAccountNumber, modemMacAddress },
  });

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="p-4">
      {!isMainScreen && (
        <button
          onClick={() => setIsVisible(!isVisible)}
          className=" flex justify-between  w-full text-left text-black bg-gray-200 px-4 py-2 font-semibold rounded-md mb-2"
        >
          {isVisible
            ? "Hide Modem Diagnostic Information"
            : "Show Modem Diagnostic Information"}
          {isVisible ? (
            <FiChevronUp className="mt-1" />
          ) : (
            <FiChevronDown className="mt-1" />
          )}
        </button>
      )}
      {isVisible && (
        <FieldSectionCard title="Modem Diagnostic Information" columns={1}>
          {isError && !isLoading ? (
            <div className="p-4 bg-gray-300 flex text-center items-center gap-4 font-semibold">
              {" "}
              <IoIosWarning size={50} color="red" />
              Modem diagnostic information could not be retrieved
            </div>
          ) : (
            <div>
              <FormField label="ESN:" type="info" value={data?.modemESN} />
              <FormField
                label="MAC:"
                type="info"
                value={data?.modemMacAddress}
              />
              <FormField
                label="Modem Details:"
                type="info"
                value={data?.ModemDetails}
              />
              <FormField
                label="Up Time:"
                type="info"
                value={data?.ModemUpTime}
              />
              <FormField
                label="CPE IP (IPv4/IPv6):"
                type="info"
                value={data?.CPE_IP?.join(" | ")}
              />
              <FormField
                label="Signal Levels:"
                type="info"
                value={`Upstream (Tx): ${data?.upstreamSignalLevel}\nDownstream (Rx): ${data?.downStreamSignalLevel}`}
              />
              <FormField label="SNR:" type="info" value={data?.modemSnr} />
            </div>
          )}
        </FieldSectionCard>
      )}
    </div>
  );
};

export default ModemDiagnosticInformation;
