import React, { useEffect } from "react";
import FieldSectionCard from "../FieldSectionCard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useGetQuery } from "../../redux/apis";
import LoadingSpinner from "../LoadingSpinner";
import { setCustomer } from "../../redux/customerSlice";

const AccountInformationView: React.FC<any> = ({ id }) => {
  const dispatch = useDispatch();
  const customer = useSelector((state: RootState) => state.customer.customer);

  const { data, isLoading, isError } = useGetQuery(
    {
      actionName: "servicerequests_account",
      transactionData: { rogersAccountNumber: id },
    },
    {
      skip: !id,
    }
  );

  useEffect(() => {
    if (data?.person?.rogersAccountNum) {
      dispatch(setCustomer(data));
    }
  }, [data, dispatch]);

  if (isLoading) return <LoadingSpinner />;
  if (isError)
    return (
      <div className="m-5 rounded-md text-white bg-secondary text-center p-5 font-semibold">
        Unable to fetch record
      </div>
    );

  return (
    <div className="p-4">
      <FieldSectionCard title="Account Information" columns={3}>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Rogers Account#:</label>
          <p>{customer?.person?.rogersAccountNum}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">First Name:</label>
          <p>{customer?.person?.firstName}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Last Name:</label>
          <p>{customer?.person?.lastName}</p>
        </div>
      </FieldSectionCard>
      <FieldSectionCard title="Address" columns={3}>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Street Number:</label>
          <p>{customer?.address?.streetNumber}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Street Name:</label>
          <p>{customer?.address?.streetName}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Street Type:</label>
          <p>{customer?.address?.streetType}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Suite/Unit#:</label>
          <p>{customer?.address?.suite || customer?.address?.aptNum}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">City/Municipality:</label>
          <p>{customer?.address?.city}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Postal Code:</label>
          <p>{customer?.address?.postalCode}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Residential Phone#:</label>
          <p>{customer?.address?.phone}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">POI Type:</label>
          <p>{customer?.address?.poiType}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Feed Type:</label>
          <p>{customer?.address?.feedType}</p>
        </div>
      </FieldSectionCard>
      <FieldSectionCard title="Service" columns={1}>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Service Type:</label>
          <p>{customer?.serviceType?.serviceType}</p>
        </div>
      </FieldSectionCard>
      <FieldSectionCard title="Modem" columns={3}>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Modem Model:</label>
          <p>{customer?.modem?.modemModel}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Modem ESN:</label>
          <p>{customer?.modem?.modemEsn}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Modem MAC:</label>
          <p>{customer?.modem?.modemMac}</p>
        </div>
      </FieldSectionCard>
    </div>
  );
};

export default AccountInformationView;
