import Radio from "../components/Radio";
import TextArea from "../components/TextArea";

export const getQuestions = (data) => {
  const unique = [
    ...new Set(data?.troubleShoot?.map((item) => item.questionSection)),
  ];
  return unique.map((section) => {
    return data?.troubleShoot
      ?.filter((filter) => filter.questionSection === section)
      ?.sort((a, b) => a.questionId - b.questionId)
      ?.map((value, key) => {
        if (value.responseType === "radiobutton") {
          return (
            <Radio
              label={value.questionDesc}
              name={value.questionId}
              key={key}
              required={value.mandatoryFlag === "Y"}
            />
          );
        } else {
          return (
            <TextArea
              label={value.questionDesc}
              name={value.questionId}
              key={key}
              required={value.mandatoryFlag === "Y"}
            />
          );
        }
      });
  });
};
