import React from "react";

interface FormFieldProps {
  label: string;
  type:
    | "text"
    | "number"
    | "date"
    | "radio"
    | "textarea"
    | "select"
    | "info"
    | "button"
    | "submit";
  value?: string | number;
  defualtValue?: string | number;
  onChange?: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => void;
  placeholder?: string;
  options?: { label: string; value: string | number }[];
  name?: string;
  disable?: boolean;
  onClick?: () => void;
  buttonLabel?: string;
  required?: boolean;
  pattern?: any;
  readonly?: boolean;
  loading?: boolean;
  title?: string;
}

const FormField: React.FC<FormFieldProps> = ({
  label,
  type,
  value,
  defualtValue,
  disable,
  onChange,
  placeholder,
  options,
  name,
  onClick,
  buttonLabel,
  required = false,
  pattern,
  readonly,
  loading,
  title,
}) => {
  const today = new Date().toISOString().split("T")[0];

  return (
    <div className="col-span-1 mb-4">
      {type !== "button" && type !== "info" && (
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}

      {type === "text" || type === "number" || type === "date" ? (
        <input
          type={type}
          className="read-only:bg-gray-200 mt-1 block w-full md:w-1/2 lg:min-w-52 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          value={value}
          defaultValue={defualtValue}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disable}
          min={today}
          name={name}
          required={required}
          pattern={pattern}
          title={title}
          readOnly={readonly}
        />
      ) : type === "textarea" ? (
        <textarea
          className="mt-1 block w-full md:w-2/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          value={value}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          defaultValue={defualtValue}
          required={required}
        />
      ) : type === "select" ? (
        <select
          className="mt-1 block w-full md:w-1/2 lg:min-w-52 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          value={value}
          name={name}
          onChange={onChange}
          disabled={disable}
          required={required}
        >
          <option value="">{placeholder}</option>
          {options?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : type === "radio" && options ? (
        <div className="flex items-center">
          {options.map((option, index) => (
            <div key={index} className="flex items-center mr-4">
              <input
                type="radio"
                id={`${name}-${index}`}
                name={name}
                value={option.value}
                checked={value === option.value}
                onChange={onChange}
                className="mr-2"
                required={required}
              />
              <label htmlFor={`${name}-${index}`} className="text-gray-700">
                {option.label}
              </label>
            </div>
          ))}
        </div>
      ) : type === "button" ? (
        <button
          type="button"
          className="disabled:bg-gray-400 w-full md:w-1/2 lg:w-52 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          onClick={onClick}
          disabled={disable}
        >
          {buttonLabel}
        </button>
      ) : type === "submit" ? (
        <button
          type="submit"
          disabled={disable}
          className="disabled:bg-gray-400  w-full md:w-1/2 lg:w-52 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          {loading ? "Loading..." : buttonLabel}
        </button>
      ) : type === "info" ? (
        <div className="">
          <label className="block font-semibold">{label}</label>
          <p className="w-full">{value}</p>
        </div>
      ) : null}
    </div>
  );
};

export default FormField;
