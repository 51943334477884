import React, { useEffect, useState } from "react";
import FieldSectionCard from "../FieldSectionCard";
import { useGetQuery } from "../../redux/apis";
import LoadingSpinner from "../LoadingSpinner";

const NewInstallationView: React.FC<any> = ({ id }) => {
  const [customer, setCustomer] = useState<any>(null);

  const {
    data: dataView,
    isLoading: ViewLoading,
    isError: ViewError,
  } = useGetQuery(
    {
      actionName: "transaction_openRequest",
      transactionData: { transactionId: id },
    },
    {
      skip: !id,
    }
  );

  useEffect(() => {
    if (dataView?.transactionData[0]) {
      setCustomer(dataView.transactionData[0]);
    }
  }, [dataView]);

  if (ViewLoading) return <LoadingSpinner />;
  if (ViewError)
    return (
      <div className="m-5 rounded-md text-white bg-secondary text-center p-5 font-semibold">
        Unable to fetch record
      </div>
    );

  return (
    <div className="p-4">
      <FieldSectionCard title="Account Information" columns={3}>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Rogers Account#:</label>
          <p>{customer?.rogersAccountNum}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">First Name:</label>
          <p>{customer?.firstName}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Last Name:</label>
          <p>{customer?.lastName}</p>
        </div>
      </FieldSectionCard>
      <FieldSectionCard title="Address" columns={3}>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Street Number:</label>
          <p>{customer?.streetNumber}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Street Name:</label>
          <p>{customer?.streetName}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Street Type:</label>
          <p>{customer?.streetType}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Suite/Unit#:</label>
          <p>{customer?.suite || customer?.aptNum}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">City/Municipality:</label>
          <p>{customer?.city}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Postal Code:</label>
          <p>{customer?.postalCode}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Residential Phone#:</label>
          <p>{customer?.phone}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">POI Type:</label>
          <p>{customer?.poiType}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Feed Type:</label>
          <p>{customer?.feedType}</p>
        </div>
      </FieldSectionCard>
      <FieldSectionCard title="Service" columns={1}>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Service Type:</label>
          <p>{customer?.serviceType}</p>
        </div>
      </FieldSectionCard>
      <FieldSectionCard title="Modem" columns={3}>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Modem Model:</label>
          <p>{customer?.modemModel}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Modem ESN:</label>
          <p>{customer?.modemEsn}</p>
        </div>
        <div className="flex gap-2">
          <label className="font-semibold w-40">Modem MAC:</label>
          <p>{customer?.modemMac}</p>
        </div>
      </FieldSectionCard>
    </div>
  );
};

export default NewInstallationView;
