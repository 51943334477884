import { useState } from "react";
import { useLazyGetQuery } from "../redux/apis";
import LoadingSpinner from "../components/LoadingSpinner";
import { useDispatch } from "react-redux";
import { setCustomer } from "../redux/customerSlice";
import CreateNewCustomerForm from "../components/Miscellaneous/NewCustomerForm";
import ExistingCustomerForm from "../components/Miscellaneous/ExistingCustomerForm";
import FormField from "../components/FormField";
import FieldSectionCard from "../components/FieldSectionCard";

const Miscellaneous: React.FC = () => {
  const dispatch = useDispatch();

  const [customerType, setCustomerType] = useState("new");
  const [viewComponent, setViewComponent] = useState(false);
  const [customerNo, setCustomerNo] = useState("");

  const [getExistingClient, { isLoading: existingClientLoading }] =
    useLazyGetQuery();

  if (existingClientLoading) {
    return (
      <div className="lg:px-10 lg:pt-5 w-full h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  const handleContinue = async (e: any) => {
    e.preventDefault();
    if (customerType === "existing") {
      const res = await getExistingClient({
        actionName: "servicerequests_account",
        transactionData: { rogersAccountNumber: customerNo },
      });
      if (res.data) {
        dispatch(setCustomer(res.data));
      } else {
        return (
          <div className="flex justify-center items-center h-screen">
            <h1 className="text-3xl font-semibold">
              Something went wrong while fetching customer information
            </h1>
          </div>
        );
      }
    }
    setViewComponent(true);
  };

  return (
    <div className="container mx-auto p-5 lg:px-20 md:px-5 sm:px-10 min-h-screen">
      {!viewComponent && (
        <>
          <h2 className="text-2xl font-normal">
            Select the Miscellaneous Request to be created for :
          </h2>

          <form className="my-4" onSubmit={handleContinue}>
            <div className="flex flex-row items-center mb-2">
              <input
                type="radio"
                id="new_install"
                name="install_type"
                value="new"
                defaultChecked
                onChange={(e) => setCustomerType(e.target.value)}
                className="mr-2"
              />
              <label htmlFor="new_install" className="text-gray-700">
                New Customer
              </label>
              <input
                type="radio"
                id="existing"
                name="install_type"
                value="existing"
                onChange={(e) => setCustomerType(e.target.value)}
                className="m-2"
              />
              <label htmlFor="existing" className="text-gray-700">
                Existing Customer
              </label>
            </div>

            {customerType === "existing" && (
              <FieldSectionCard title="" columns={3}>
                <FormField
                  label=""
                  type="text"
                  name="customerNo"
                  placeholder="Enter Customer No"
                  onChange={(e) => setCustomerNo(e.target.value)}
                />
              </FieldSectionCard>
            )}
            <FormField
              type="submit"
              label=""
              buttonLabel="Continue"
              loading={existingClientLoading}
            />
          </form>
        </>
      )}
      <hr />
      {viewComponent && (
        <>
          {customerType === "new" ? (
            <CreateNewCustomerForm setViewComponent={setViewComponent} />
          ) : (
            <>
              {!existingClientLoading ? (
                <ExistingCustomerForm setViewComponent={setViewComponent} />
              ) : (
                <div className="lg:px-10 lg:pt-5 w-full h-screen">
                  <LoadingSpinner />
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Miscellaneous;
