import React, { useState, useEffect } from "react";
import { RiMenuUnfold3Fill } from "react-icons/ri";
import { RiMenuFoldLine } from "react-icons/ri";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { Link, NavLink } from "react-router-dom";

interface SidebarNavigationProps {
  prams: any;
  shawId: any;
}

const sections = [
  {
    title: "Service Changes",
    items: [
      { label: "Speed change", section: "changeSpeedTier" },
      { label: "Terminate service", section: "disconnect" },
      { label: "Reconnect service", section: "reconnect" },
      { label: "Reschedule Install", section: "reschedule" },
      { label: "Modem swap", section: "replaceModem" },
      { label: "Relocate Outlet", section: "relocateOutlet" },
      { label: "Transactions", section: "transactions" },
      { label: "Address move", section: "moveCustomer" },
    ],
  },
  {
    title: "Ticketing",
    items: [
      { label: "Modem Status", section: "modemDiagnosticInformation" },
      { label: "RF Ticket", section: "rfTroubleshooting" },
      { label: "Slow Speed Ticket", section: "slowSpeedTroubleshooting" },
      { label: "Provisioning Issue Ticket", section: "provisioningIssue" },
      { label: "DHCP Ticket", section: "dhcpTroubleshooting" },
      { label: "IP Routing Issues", section: "serviceRoutingIssues" },
    ],
  },
];

const SidebarNavigation: React.FC<SidebarNavigationProps> = ({
  prams,
  shawId,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>(
    {}
  );

  const toggleSection = (title: string) => {
    setOpenSections((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  useEffect(() => {
    // Open the first section by default
    if (sections.length > 0) {
      setOpenSections({ [sections[0].title]: true, [sections[1].title]: true });
    }
  }, []);

  return (
    <div className="bg-white shadow-md rounded-md px-4 py-1 w-full lg:w-4/4">
      <div className="flex justify-between items-center mb-4 md:hidden">
        <h2 className="text-lg font-semibold">Existing Customer</h2>
        <button
          className="px-3 py-2 bg-primary text-white rounded"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          {isSidebarOpen ? <RiMenuFoldLine /> : <RiMenuUnfold3Fill />}
        </button>
      </div>
      <div className={`${isSidebarOpen ? "block" : "hidden"} md:block`}>
        {(shawId === "41400043498" || shawId === "41400030194") && (
          <Link
            className={`!w-full block text-left text-white bg-primary px-4 py-2 mt-1 font-semibold rounded-md mb-2 $`}
            to={`/search-criteria/${prams}`}
          >
            Transactions Dashboard
          </Link>
        )}
        <Link
          className={`!w-full block text-left text-white bg-primary px-4 py-2 mt-1 font-semibold rounded-md mb-2 $`}
          to={`.${prams}`}
        >
          Account Information
        </Link>
        {sections.map((section, index) => (
          <div key={index} className="mb-4">
            <button
              className="w-full flex justify-between items-center text-left text-white bg-primary px-4 py-2 font-semibold rounded-md mb-2"
              onClick={() => toggleSection(section.title)}
            >
              {section.title}
              {openSections[section.title] ? (
                <FiChevronUp />
              ) : (
                <FiChevronDown />
              )}
            </button>
            {openSections[section.title] && (
              <ul className="mt-2">
                {section.items.map((item, index) => (
                  <li key={index}>
                    <NavLink
                      className={`block px-4 py-1 hover:bg-gray-100 cursor-pointer border-b-1`}
                      to={`${item.section}/${prams}`}
                    >
                      {item.label}
                    </NavLink>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SidebarNavigation;
