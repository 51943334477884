import React, { useState } from "react";
import { useLazyGetQuery } from "../redux/apis";
import moment from "moment";

interface DateWindowSelectorProps {}

const DateWindowSelector: React.FC<DateWindowSelectorProps> = ({}) => {
  const [option1, setOption1] = useState();
  const [option2, setOption2] = useState();
  const today = new Date().toISOString().split("T")[0];

  const [getWindowData, { data: windowData = [], isFetching: loading }] =
    useLazyGetQuery();

  const handleFetchWindows = async () => {
    if (option1 && option2) {
      try {
        await getWindowData({
          actionName: "servicerequests_reconnectWindows",
          transactionData: {
            timeSlot: {
              option1: moment(option1).format("MM/DD/YYYY"),
              option2: moment(option2).format("MM/DD/YYYY"),
              option3: "",
              preferredWindow1: "",
              preferredWindow2: "",
              preferredWindow3: "",
              roleName: "Internet Access Partner Admin",
            },
          },
        });
      } catch (e) {}
    }
  };

  return (
    <div className="lg:flex sm:block gap-0 justify-around">
      <div className="block lg:w-4/12">
        <label className="block text-gray-700 font-normal mb-2">
          Option 1 *
        </label>
        <input
          type="date"
          name="option1"
          value={option1}
          min={today}
          onChange={(e: any) => setOption1(e.target.value)}
          required={true}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
        <label className="block text-gray-700 font-normal mb-2">
          Option 2 *
        </label>
        <input
          type="date"
          name="option2"
          value={option2}
          required={true}
          min={today}
          onChange={(e: any) => setOption2(e.target.value)}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div className="block lg:w-2/12 items-center  mt-8">
        <div className="block">
          <button
            className="w-full px-4 py-2 disabled:bg-gray-400  bg-primary text-white rounded hover:bg-secondary"
            type="button"
            disabled={!option1 || !option2 || loading}
            onClick={handleFetchWindows}
          >
            {loading ? "Loading..." : "Get Windows"}
          </button>
        </div>
      </div>
      <div className="lg:w-4/12">
        <label className="block  text-gray-700 font-normal mb-2">
          Preferred Window 1 *
        </label>
        <select
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          name="preferredWindow1"
          disabled={loading}
          required={true}
        >
          <option value="">Select ...</option>
          {windowData
            ?.filter((val: any) => parseInt(val.dateStr) === 1)
            ?.map((value: any, index: any) => (
              <option key={index} value={value.slotDescription}>
                {value.slotDescription}
              </option>
            ))}
        </select>
        <label className="block text-gray-700 font-normal mb-2">
          Preferred Window 2 *
        </label>
        <select
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          name="preferredWindow2"
          disabled={loading}
          required={true}
        >
          <option value="">Select ...</option>
          {windowData
            ?.filter((val: any) => parseInt(val.dateStr) === 2)
            ?.map((value: any, index: any) => (
              <option key={index} value={value.slotDescription}>
                {value.slotDescription}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
};

export default DateWindowSelector;
