import { useSelector } from "react-redux";
import LoadingSpinner from "../../LoadingSpinner";
import { RootState } from "../../../redux/store";
import { useGetQuery } from "../../../redux/apis";
import { useNavigate, useOutletContext } from "react-router-dom";
interface Transaction {
  trxId: number;
  workOrderId: string | null;
  trxType: string;
  rogersAccountNo: string;
  createdDate: string;
  status: string;
  [key: string]: any;
}

const TransactionsList: React.FC = () => {
  const context = useOutletContext<any>();

  const navigate = useNavigate();
  const accountNumber = useSelector(
    (state: RootState) => state.customer?.customer?.person?.rogersAccountNum
  );
  const searchParams = {
    trxId: "",
    workorderId: "",
    txnType: "",
    createdDate: "",
    slaDate: "",
    modifyBy: "",
    status: "",
    technologyType: "",
    rogersAccountNo: accountNumber,
    faId: "",
    searchType: "searchBtn_hasValue",
    pageNumber: 1,
    sourceType: "",
    accountNumber,
  };
  const { data, isLoading, isError } = useGetQuery({
    actionName: "transaction_search",
    transactionData: searchParams,
  });
  let transaction = [];
  if (data?.transaction?.length > 0) {
    transaction = [...data.transaction].sort(
      (a: Transaction, b: Transaction) => {
        const dateA: Date = new Date(a.modifyDate);
        const dateB: Date = new Date(b.modifyDate);
        return dateB.getTime() - dateA.getTime();
      }
    );
  }
  const handleView = async (transaction: Transaction) => {
    navigate(`/transaction/${transaction.trxId}/${context?.prams}`);
  };

  {
    isError && (
      <div className="mt-3 rounded-md text-white bg-secondary text-center p-5 font-semibold">
        Failed to fetch transactions
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col p-3">
      <div className="container mx-auto flex-grow">
        {isLoading && <LoadingSpinner />}

        {data && data.totalSearchResults === 0 && (
          <div className="mt-3 rounded-md text-white bg-secondary text-center p-5 font-semibold">
            No result Found
          </div>
        )}

        {data && data.transaction && data.transaction.length > 0 && (
          <div className="mt-6 overflow-y-auto">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr className="bg-secondary text-white text-center">
                  <th className="py-2 px-4 border-b">Transaction ID</th>
                  <th className="py-2 px-4 border-b">Transaction Type</th>
                  <th className="py-2 px-4 border-b">Submission Date</th>
                  <th className="py-2 px-4 border-b">Modified Date</th>
                  <th className="py-2 px-4 border-b">Status</th>
                  <th className="py-2 px-4 border-b"></th>
                </tr>
              </thead>
              <tbody>
                {transaction.map((transaction: Transaction) => (
                  <tr key={transaction.trxId} className="text-center">
                    <td className="py-2 px-4 border-b">{transaction.trxId}</td>
                    <td className="py-2 px-4 border-b">
                      {transaction.trxType}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {transaction.createdDate}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {transaction.modifyDate}
                    </td>
                    <td className="py-2 px-4 border-b">{transaction.status}</td>
                    {/* {accountNumber === "41400043498" && (
                      <td className="py-2 px-4 border-b">
                        {transaction.rogersAccountNo}
                      </td>
                    )} */}
                    <td className="py-2 px-4 border-b">
                      <button
                        className="text-blue-500 hover:text-blue-700"
                        onClick={() => handleView(transaction)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionsList;
