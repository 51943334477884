import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CustomerState {
  customer: any;
  address: any;
}

const initialState: CustomerState = {
  customer: {},
  address: {},
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomer: (state, action: PayloadAction<any>) => {
      const customer = action.payload;
      state.customer = customer;
    },
    setAddress: (state, action: PayloadAction<any>) => {
      const newAddress = action.payload;
      state.address = newAddress;
    },
    clearAddress: (state) => {
      state.address = null;
    },
  },
});

export const { setAddress, clearAddress, setCustomer } = customerSlice.actions;
export default customerSlice.reducer;
