export const postalRegex = {
  regex:
    "[ABCEGHJ-NPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z]\\d[ABCEGHJ-NPRSTV-Z]\\d|\\d{5}|\\d{5}(-\\d{4})",
  title:
    "Enter a valid postal code in the format A1A1A1 OR 12345 OR 12345-1234.",
};

export const macRegex = {
  regex: "[0-9A-Fa-f]{12}",
  title: "Example: 001A2B3C4D5E",
};

export const residentialPhone = {
  regex: "^[1-9]{1}[0-9]{9}$",
  title: "Example: 10 Digit Number",
};
