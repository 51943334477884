import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; // Importing arrow icons

interface Transaction {
  trxId: number;
  workOrderId: string | null;
  trxType: string;
  rogersAccountNo: string;
  createdDate: string;
  status: string;
  [key: string]: any;
}

const TransactionsSearchList: React.FC<any> = ({ prams }) => {
  const navigate = useNavigate();
  const transaction =
    useSelector(
      (state: RootState) => state.transaction.searchTransactionsList
    ) || [];

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handleView = async (transaction: Transaction) => {
    navigate(`/transaction/${transaction.trxId}/${prams}`);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = transaction.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(transaction.length / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
  
    if (totalPages <= maxPagesToShow + 2) {
      // If the total pages are less than or equal to max pages to show + first and last pages
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 mx-1 border rounded ${
              currentPage === i ? "bg-secondary text-white" : "bg-gray-200 text-gray-700"
            }`}
          >
            {i}
          </button>
        );
      }
    } else {
      // Show the first page button
      pageNumbers.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className={`px-2 py-1 mx-1 border rounded ${
            currentPage === 1 ? "bg-secondary text-white" : "bg-gray-200 text-gray-700"
          }`}
        >
          1
        </button>
      );
  
      if (currentPage > maxPagesToShow) {
        pageNumbers.push(<span key="start-ellipsis" className="mx-1">...</span>);
      }
  
      let startPage = Math.max(2, currentPage);
      let endPage = Math.min(currentPage + maxPagesToShow - 1, totalPages - 1);
  
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 mx-1 border rounded ${
              currentPage === i ? "bg-secondary text-white" : "bg-gray-200 text-gray-700"
            }`}
          >
            {i}
          </button>
        );
      }
  
      if (endPage < totalPages - 1) {
        pageNumbers.push(<span key="end-ellipsis" className="mx-1">...</span>);
      }
  
      // Show the last page button
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={`px-2 py-1 mx-1 border rounded ${
            currentPage === totalPages ? "bg-secondary text-white" : "bg-gray-200 text-gray-700"
          }`}
        >
          {totalPages}
        </button>
      );
    }
  
    return pageNumbers;
  };

  return transaction.length > 0 ? (
    <div className="min-h-screen flex flex-col p-3">
      <div className="container mx-auto flex-grow">
       
        <div className="mt-6 overflow-y-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="bg-secondary text-white text-center">
                <th className="py-2 px-4 border-b">Transaction ID</th>
                <th className="py-2 px-4 border-b">Transaction Type</th>
                <th className="py-2 px-4 border-b">Rogers Account No</th>
                <th className="py-2 px-4 border-b">Submission Date</th>
                <th className="py-2 px-4 border-b">Modified Date</th>
                <th className="py-2 px-4 border-b">Status</th>
                <th className="py-2 px-4 border-b"></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((transaction: Transaction) => (
                <tr
                  key={transaction.trxId}
                  className={`text-center hover:bg-gray-300`}
                >
                  <td className="py-2 px-1 border-b">{transaction.trxId}</td>
                  <td className="py-2 px-1 border-b">{transaction.trxType}</td>
                  <td className="py-2 px-1 border-b">
                    {transaction.rogersAccountNo}
                  </td>
                  <td className="py-2 px-1 border-b">
                    {transaction.createdDate}
                  </td>
                  <td className="py-2 px-1 border-b">
                    {transaction.modifyDate}
                  </td>
                  <td className="py-2 px-1 border-b">{transaction.status}</td>
                  <td className="py-2 px-1 border-b">
                    <button
                      className="text-blue-500 hover:text-blue-700"
                      onClick={() => handleView(transaction)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> <div className="flex justify-between items-center m-4">
          <label className="text-gray-700">
            Show
            <select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
              className="m-3 p-1 border border-gray-300 rounded"
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </select>
            entries
          </label>
       
        <div className="mt-4 flex justify-center items-center">
          <button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className="px-3 py-2 mx-1 bg-gray-200 text-gray-700 rounded disabled:opacity-50"
          >
            <FaArrowLeft />
          </button>
          {renderPageNumbers()}
          <button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            className="px-3 py-2 mx-1 bg-gray-200 text-gray-700 rounded disabled:opacity-50"
          >
            <FaArrowRight />
          </button>
        </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="mt-3 rounded-md text-white bg-secondary text-center p-5 font-semibold">
      No result Found
    </div>
  );
};

export default TransactionsSearchList;
