import React, { useState } from "react";
import FieldSectionCard from "../../FieldSectionCard";
import FormField from "../../FormField";
import ModemDiagnosticInformation from "./ModemDiagnosticInformation";
import { useSelector } from "react-redux";
import {
  useGetQuery,
  useImageMutation,
  usePostMutation,
} from "../../../redux/apis";
import { toast } from "react-toastify";
import Radio from "../../Radio";
import TextArea from "../../TextArea";
import LoadingSpinner from "../../LoadingSpinner";
import { getQuestions } from "../../../utility/questions";

const ServiceRoutingIssue: React.FC<any> = ({ modemDetails }) => {
  const [skipGetQuestions, setSkipGetQuestions] = useState(false);
  const { customer } = useSelector((state: any) => state.customer);
  const { data, isLoading: getLoading } = useGetQuery(
    {
      actionName: "fetchtroubleshootingquestions",
      transactionData: {
        troubleshootingType: "Service Routing Issue Troubleshooting",
        technologyType: customer.technologyType,
      },
    },
    { skip: skipGetQuestions }
  );
  const [rf, { isLoading }] = usePostMutation();
  const [image] = useImageMutation();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { person, address, modem, serviceType, technologyType } = customer;
    const { fileName, ...form }: any = Object.fromEntries(
      new FormData(e.target as HTMLFormElement).entries()
    );
    const troubleshooting = Object.entries(form).map(([key, value]) => ({
      questionId: key,
      response: value,
      tsType: "Service Routing Issue Troubleshooting",
    }));
    try {
      // if (fileName) {
      //   await image(fileName);
      // }
      const { data } = await rf({
        actionName: "submittroubleshooting",
        transactionData: {
          createdBy: "joshua@ciphertv.com",
          person: { ...person, beCode: "WE12", tpiaName: "AEBC" },
          address,
          modem,
          serviceType,
          technologyType,
          timeSlot: {
            option1: "",
            option2: "",
            option3: "",
            preferredWindow1: "",
            preferredWindow2: "",
            preferredWindow3: "",
          },
          troubleshooting,
          fileName: fileName?.name || "",
        },
      });
      toast.info(
        data?.response ? `${data?.response}, ${data?.trxId}` : data?.responseMsg
      );
      setSkipGetQuestions(true);
    } catch (error) {
      toast.info("Somthing is Wrong");
    }
  };

  if (getLoading) {
    return <LoadingSpinner />;
  }
  return (
    <div className="p-4">
      <FieldSectionCard title="Service Routing Issue" columns={1}>
        <ModemDiagnosticInformation modemDetails={modemDetails} />
        <p className="text-red-500 mt-4">
          This form should only be used in the event that the customer cannot
          get to the desired webpage/Internet due to routing issues.
        </p>
        <p className="text-red-500">Please answer all mandatory questions.</p>
        <form onSubmit={handleSubmit}>
          {getQuestions(data)}
          <div className="my-4">
            <label
              className="block mb-2 text-sm font-medium text-gray-900"
              htmlFor="file_input"
            >
              Upload file
            </label>
            <input
              className="block p-2 w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
              aria-describedby="file_input_help"
              id="file_input"
              type="file"
              name="fileName"
            />
          </div>
          <FormField
            label=""
            type="submit"
            buttonLabel="Submit"
            loading={isLoading}
            disable={isLoading}
          />
        </form>
      </FieldSectionCard>
    </div>
  );
};

export default ServiceRoutingIssue;
