import React from "react";
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";

const Modal: React.FC<any> = ({ data, onClose, children }) => {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-4 rounded shadow-lg relative w-3/4 max-w-2xl">
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 font-semibold"
          onClick={onClose}
        >
          &times;
        </button>
        {children ? (
          <>{children}</>
        ) : (
          <p className="p-2 font-semibold overflow-auto h-96">
            <JsonView
              data={data}
              shouldExpandNode={allExpanded}
              style={defaultStyles}
            />
          </p>
        )}
      </div>
    </div>
  );
};

export default Modal;
