import React from "react";
import FieldSectionCard from "../../FieldSectionCard";
import FormField from "../../FormField";
import DateWindowSelector from "../../DateWindowSelector";
import { usePostMutation } from "../../../redux/apis";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { ispGetMoveOutlate } from "../../../utility/ispManagerPrams";

const RelocateOutlet: React.FC = () => {
  const [relocate, { isLoading }] = usePostMutation();
  const { customer } = useSelector((state: any) => state.customer);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { person, address, modem, serviceType, technologyType } = customer;
    const {
      buzzCode,
      altContactPhnNum,
      option1,
      option2,
      preferredWindow1,
      preferredWindow2,
    }: any = Object.fromEntries(
      new FormData(e.target as HTMLFormElement).entries()
    );
    const transactionData = {
      createdBy: "joshua@ciphertv.com",
      tpianame: person.tpianame,
      address,
      modem,
      person: { ...person, beCode: "WE12", tpiaName: "AEBC" },
      serviceType,
      technologyType,
      relocateDetails: {
        buzzCode,
        altContactPhnNum,
        timeSlot: {
          option1: moment(option1).format("MM/DD/YYYY"),
          option2: moment(option2).format("MM/DD/YYYY"),
          option3: "",
          preferredWindow1,
          preferredWindow2,
          preferredWindow3: "",
          roleName: "Internet Access Partner Admin",
        },
      },
    };
    try {
      const { data } = await relocate({
        actionName: "servicerequests_relocateOutlet",
        transactionData,
        ispmgr: ispGetMoveOutlate(transactionData),
      });

      toast.info(
        data?.response ? `${data?.response}, ${data?.trxId}` : data?.responseMsg
      );
    } catch (e) {
      toast.info("Error");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4">
      <FieldSectionCard title="Relocate Outlet" columns={2}>
        <FormField
          label="Buzz Code"
          type="text"
          name="buzzCode"
          placeholder="Buzz Code"
          required
        />
        <FormField
          label="Alternate Contact Phone"
          type="text"
          name="altContactPhnNum"
          placeholder="Alternate Contact Phone"
          required
        />
      </FieldSectionCard>
      <FieldSectionCard title="Relocate Outlet" columns={1}>
        <DateWindowSelector />
      </FieldSectionCard>

      <FormField
        label=""
        type="submit"
        buttonLabel="Submit"
        loading={isLoading}
        disable={isLoading}
      />
    </form>
  );
};

export default RelocateOutlet;
