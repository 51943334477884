import React, { useState } from "react";
import CheckAvailablilty from "../components/HomeScreen/CheckAvailablity";
import CreateNewCustomerForm from "../components/HomeScreen/NewCustomerForm";
import { useSearchParams } from "react-router-dom";
import CreateNewCustomerFormManual from "../components/HomeScreen/NewCustomerFormManual";

const NewCustomer: React.FC = () => {
  // ?auth=221c58c1&serviceid=52123&username=Melinda&accountid=AE060306-3613&shawId=AE060306-3613&isp=aebc.com
  const [queryParams] = useSearchParams();

  // const auth = queryParams.get("auth");
  // const serviceid = queryParams.get("serviceid");
  const username = queryParams.get("username");

  const accountid = queryParams.get("accountid");
  const isp = queryParams.get("isp");
  const [formOpen, setFormOpen] = useState(null);
  const [installType, setInstallType] = useState("new");

  if (!accountid || !username) {
    return (
      <div className="flex justify-center items-center h-screen">
        <h1 className="text-3xl font-semibold">Missing query parameters</h1>
      </div>
    );
  }

  const title =
    installType === "transfer"
      ? "Submit Transfer Request"
      : "Submit New Install Request";

  return (
    <div className="container mx-auto p-5 lg:px-20 md:px-5 sm:px-10 min-h-screen">
      <h2 className="text-2xl font-normal">{title}</h2>
      <hr />
      {!formOpen ? (
        <CheckAvailablilty
          setFormOpen={setFormOpen}
          setInstallType={setInstallType}
        />
      ) : (
        <>
          {" "}
          {formOpen === "Verified" ? (
            <CreateNewCustomerForm
              setFormOpen={setFormOpen}
              installType={installType}
              ispData={{ username, accountid, isp }}
            />
          ) : (
            <CreateNewCustomerFormManual
              setFormOpen={setFormOpen}
              installType={installType}
              ispData={{ username, accountid, isp }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default NewCustomer;
