export const isValidMacAddress = (macAddress: string): boolean => {
  const macRegex = /^[0-9A-Fa-f]{12}$/;
  return macRegex.test(macAddress);
};

export const validatePostalCode = (postalCode: string): boolean => {
  const canadaRegex =
    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][-]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
  // US postal code regex pattern
  const usRegex = /^\d{5}(-\d{4})?$/;

  // Check if the postal code matches either the Canadian or US format
  return canadaRegex.test(postalCode) || usRegex.test(postalCode);
  // return false
};

// const validateAndFormatAddress = (addressAvailability: any) => {
//     let streetType = addressAvailability.streetType.toUpperCase();
//     let streetName = addressAvailability.streetName.replace(/\bNo\b/g, 'No.');

//     setAddressAvailability({
//         ...addressAvailability,
//         streetType,
//         streetName,
//         addressAvailable: true,
//         errors: ''
//     });
// };
