import React, { useState } from "react";
import FieldSectionCard from "../../FieldSectionCard";
import FormField from "../../FormField";
import DateWindowSelector from "../../DateWindowSelector";
import TransactionsListSelect from "./TransactionsListSelect";
import { usePostMutation } from "../../../redux/apis";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";

const Reschedule: React.FC = () => {
  const { customer } = useSelector((state: any) => state.customer);
  const [selectTrx, SetSelectTrx]: any = useState({});

  const [reschedule, { isLoading }] = usePostMutation();
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { option1, option2, preferredWindow1, preferredWindow2 }: any =
      Object.fromEntries(new FormData(e.target as HTMLFormElement).entries());
    try {
      const { data } = await reschedule({
        actionName: "servicerequests_reschedule",
        transactionData: {
          createdBy: "joshua@ciphertv.com",
          trxMode: "Auto",
          person: { ...customer.person, beCode: "WE12", tpiaName: "AEBC" },
          address: customer?.address,
          transaction: {
            txnId: selectTrx?.trxId,
            txnType: selectTrx?.trxType,
            txnDate: moment(selectTrx?.createdDate).format("MM/DD/YYYY"),
            txnStatus: selectTrx?.status,
            serviceCallId: selectTrx?.serviceCallId,
            parentTrxType: selectTrx?.parentTrxType,
          },
          timeSlot: {
            option1: moment(option1).format("MM/DD/YYYY"),
            option2: moment(option2).format("MM/DD/YYYY"),
            option3: "",
            preferredWindow1: preferredWindow1,
            preferredWindow2: preferredWindow2,
            preferredWindow3: "",
          },
          technologyType: customer?.technologyType,
        },
      });
      toast.info(
        data?.response ? `${data?.response}, ${data?.trxId}` : data?.responseMsg
      );
    } catch (error) {
      toast.info("Error");
    }
  };

  return (
    <form className="p-4" onSubmit={handleSubmit}>
      <TransactionsListSelect SetSelectTrx={SetSelectTrx} />
      <FieldSectionCard title="Reschedule" columns={1}>
        <DateWindowSelector />
      </FieldSectionCard>
      <FormField
        label=""
        type="submit"
        buttonLabel="Submit"
        loading={isLoading}
        disable={isLoading}
      />
    </form>
  );
};

export default Reschedule;
