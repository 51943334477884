import React from "react";

const TextArea = ({ label, required, name }) => {
  return (
    <div className="space-y-1 mb-4">
      <label className="font-semibold">{label}</label>
      <textarea
        className="mt-1 block w-full md:w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        required={required}
        name={name}
      />
    </div>
  );
};

export default TextArea;
