import React from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGetQuery } from "../redux/apis";
import SidebarNavigation from "../components/SidebarNavigation";
import LoadingSpinner from "../components/LoadingSpinner";
import { setCustomer } from "../redux/customerSlice";
import { RootState } from "../redux/store";

const CustomerDetails: React.FC = () => {
  const customer = useSelector((state: RootState) => state.customer.customer);

  const [queryParams] = useSearchParams();
  const dispatch = useDispatch();

  const auth = queryParams.get("auth");
  const serviceid = queryParams.get("serviceid");
  const username = queryParams.get("username");

  const accountid = queryParams.get("accountid");
  const isp = queryParams.get("isp");
  const shaw = queryParams.get("shawId");

  let shawId = JSON.stringify(shaw);
  shawId = shawId.replace(/[^0-9.]/g, "");
  const prams = `?auth=${auth}&username=${username}&serviceid=${serviceid}&accountid=${accountid}&shawId=${shawId}&isp=${isp}`;
  const { data: existingClientData, isLoading: existingClientLoading } =
    useGetQuery(
      {
        actionName: "servicerequests_account",
        transactionData: { rogersAccountNumber: shawId },
      },
      { skip: !shawId || customer?.person }
    );

  if (!serviceid || !accountid || !shawId || !isp) {
    return (
      <div className="flex justify-center items-center h-screen">
        <h1 className="text-3xl font-semibold">Missing query parameters</h1>
      </div>
    );
  }

  if (existingClientLoading) {
    return (
      <div className="lg:px-10 lg:pt-5 w-full h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  if (existingClientData?.person?.rogersAccountNum || customer?.person) {
    dispatch(setCustomer(existingClientData || customer));
  } else {
    return (
      <div className="flex justify-center items-center h-screen">
        <h1 className="text-3xl font-semibold">
          Something went wrong while fetching customer information
        </h1>
      </div>
    );
  }

  return (
    <div className="lg:px-10 lg:pt-5">
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/4 lg:w-1/4 p-2">
          <SidebarNavigation prams={prams} shawId={shawId} />
        </div>
        <div className="md:w-3/4">
          <Outlet
            context={{ username, accountid, serviceid, shawId, isp, prams }}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
