import React, { useState } from "react";
import {
  useGetQuery,
  useLazyGetQuery,
  usePostMutation,
} from "../../redux/apis";
import FieldSectionCard from "../FieldSectionCard";
import FormField from "../FormField";
import LoadingButton from "../LoadingButton";
import { toast } from "react-toastify";

const MiscellaneousNewCustomerForm: React.FC<any> = ({
  setFormOpen,
  setViewComponent,
}) => {
  const [formData, setFormData] = useState({
    createdBy: "joshua@ciphertv.com",
    trxMode: "Manual",
    internetPlan: "",
    technologyType: "Cable",
    streetNumber: "",
    streetName: "",
    streetType: "",
    suite: "",
    buzzCode: "",
    city: "",
    postalCode: "",
    province: "British Columbia",
    phone: "",
    nearestInter: "",
    customerType: "Residential",
    firstName: "",
    lastName: "",
    rogersAccountNum: "",
    beCode: "WE12",
    tpiaName: "AEBC",
    tpiaAccountNumber: "",
    serviceType: "TPIA Internet 10",
    modemModel: "",
    modemDesc: "",
    modemMac: "",
    modemEsn: "",
    additionalInfo1: "",
    additionalInfo2: "",
    provinceSourceType: "west",
    miscellaneousType: "New",
    fileName: "",
    requestType: "Service Order Request",
  });

  const prepareTransactionData = (data: any) => {
    return {
      createdBy: "joshua@ciphertv.com",
      trxMode: "Manual",
      internetPlan: "",
      technologyType: "Cable",
      address: {
        streetNumber: data.streetNumber || "",
        streetName: data.streetName || "",
        streetType: data.streetType || "",
        suite: data.suite || "",
        buzzCode: data.buzzCode || "",
        city: data.city || "",
        postalCode: data.postalCode || "",
        province: data.province || "British Columbia",
        phone: data.phone || "",
        nearestInter: data.nearestInter || "",
        customerType: data.customerType || "Residential",
      },
      person: {
        firstName: data.firstName,
        lastName: data.lastName,
        rogersAccountNum: data.rogersAccountNum || "",
        beCode: "WE12",
        tpiaName: "AEBC",
        tpiaAccountNumber: "",
      },
      serviceType: {
        serviceType: data.serviceType,
      },
      modem: {
        modemModel: data.modemModel,
        modemMac: data.modemMac,
        modemEsn: data.modemEsn,
      },
      additionalInfo1: data.additionalInfo1,
      additionalInfo2: data.additionalInfo2,
      provinceSourceType: "west",
      miscellaneousType: "New",
      fileName: "",
      requestType: data.requestType,
    };
  };
  const handleModemChange = (e: any) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setFormData((prevData) => ({
      ...prevData,
      modemDesc: selectedOption.value,
      modemModel: selectedOption.text,
    }));

    getServiceOptions({
      actionName: "getserviceTypeWest",
      transactionData: {
        modemManufacturerDeviceProductCode: selectedOption.value,
        mgigKey: "",
      },
    });
  };

  const setRequestType = (type: any) => {
    setFormData((prevData) => ({
      ...prevData,
      requestType: type,
    }));
  };
  const [postNewOrderService] = usePostMutation();

  const { data: provinceData } = useGetQuery({
    actionName: "getProvinceAllWest",
    transactionData: {},
  });

  const provincemedOptions = Object?.entries(provinceData || {}).map(
    ([key, value]: any) => ({ value: value, label: key })
  );

  const { data: modemData, isLoading } = useGetQuery({
    actionName: "getmodemModelWest",
    transactionData: {},
  });

  const modemDropdownValues = Object?.entries(modemData || {}).map(
    ([key, value]: any) => ({ value: value, label: key })
  );

  const [
    getServiceOptions,
    { data: serviceOptionsData, isLoading: serviceOptionsLoading },
  ] = useLazyGetQuery();

  const serviceDropdownValues = Object?.entries(serviceOptionsData || {}).map(
    ([key, value]: any) => ({ value: value, label: key })
  );

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const formEntries = new FormData(event.target as HTMLFormElement).entries();
    const form: any = Object.fromEntries(formEntries);

    const mergeData = {
      ...formData,
      ...form,
    };
    try {
      const preparedData = prepareTransactionData(mergeData);

      let response = await postNewOrderService({
        actionName: "miscellaneous",
        transactionData: preparedData,
      });
      if (response.data?.trxId) {
        toast(`${response?.data?.response}, ${response?.data?.trxId}`);
        setFormOpen(false);
      } else {
        toast(response?.errorMsg);
      }
    } catch (err: any) {
      toast(err);
    }
  };

  return (
    <div className="container mx-auto">
      <form className="space-y-6 mt-2" onSubmit={handleSubmit}>
        <FieldSectionCard title="Customer Details" columns={3}>
          <FormField
            label="First Name"
            type="text"
            name="firstName"
            placeholder="First Name"
            required
          />
          <FormField
            label="Last Name"
            type="text"
            name="lastName"
            required
            placeholder="Last Name"
          />
          <FormField
            label="Customer Type"
            type="select"
            name="customerType"
            defualtValue="Residential"
            options={[
              { label: "Residential", value: "Residential" },
              { label: "Commercial", value: "Commercial" },
            ]}
          />
        </FieldSectionCard>

        <FieldSectionCard title="Address" columns={3}>
          <FormField
            label="Street Number"
            type="text"
            name="streetNumber"
            placeholder="Street Number"
          />
          <FormField
            label="Street Name"
            type="text"
            name="streetName"
            placeholder="Street Name"
          />
          <FormField
            label="Street Type"
            type="text"
            name="streetType"
            placeholder="Street Type"
          />
          <FormField
            label="Suite/Unit#"
            type="text"
            name="suite"
            placeholder="Suite/Unit#"
          />
          <FormField
            label="Buzz Code"
            type="text"
            name="buzzCode"
            placeholder="Buzz Code"
          />
          <FormField
            label="City/Municipality"
            type="text"
            name="city"
            placeholder="City/Municipality"
          />
          <FormField
            label="Postal Code"
            type="text"
            name="postalCode"
            placeholder="Postal Code"
          />
          <FormField
            label="Province"
            type="select"
            name="province"
            options={provincemedOptions}
          />
          <FormField
            label="Phone"
            type="text"
            name="phone"
            placeholder="Phone"
          />
          <FormField
            label="Nearest Main Intersection"
            type="text"
            name="nearestInter"
            placeholder="Nearest Main Intersection"
          />
        </FieldSectionCard>

        <FieldSectionCard title="Modem Details" columns={3}>
          <FormField
            label="Modem Model"
            type="select"
            name="modemModel"
            onChange={handleModemChange}
            options={modemDropdownValues}
          />
          <FormField
            label="Modem ESN"
            type="text"
            name="modemEsn"
            placeholder="Modem ESN"
          />
          <div className="relative">
            <FormField
              label="Modem MAC"
              type="text"
              name="modemMac"
              placeholder="Modem MAC"
            />
            {/* {macAddressError && (
              <div className="text-red-500 font-normal">
                " Modem MAC Invalid"
              </div>
            )} */}
          </div>
        </FieldSectionCard>
        <FieldSectionCard title="Service" columns={3}>
          <FormField
            label="Service Type"
            type="select"
            name="serviceType.serviceType"
            options={serviceDropdownValues}
          />
          {serviceOptionsLoading && (
            <div className="flex items-center">
              <p>Please wait...</p>
              <LoadingButton />
            </div>
          )}
        </FieldSectionCard>
        <FieldSectionCard title="Additional Information" columns={1}>
          <label className="block text-sm font-medium text-gray-700">
            Request Type
          </label>
          <div className="flex gap-2">
            <input
              type="radio"
              name="requestType"
              onChange={(e) => setRequestType(e.target.value)}
              value="Service Order Request"
            />
            <label htmlFor="requestType">Service Order Request</label>
            <input
              type="radio"
              name="requestType"
              onChange={(e) => setRequestType(e.target.value)}
              value="Technical Request"
            />
            <label htmlFor="requestType">Technical Request</label>
          </div>
          <FormField
            label="Additional Info 1"
            type="textarea"
            name="additionalInfo1"
            placeholder="Additional Info 1"
          />
          <FormField
            label="Additional Info 2"
            type="textarea"
            name="additionalInfo2"
            placeholder="Additional Info 2"
          />

          <div className="flex flex-wrap sm:content-center gap-5 space-x-4 ml-4 mb-4">
            <button
              type="button"
              onClick={() => setViewComponent(false)}
              className="bg-primary text-white py-2 px-4 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className="bg-red-500 text-white py-2 px-4 rounded disabled:bg-gray-300 flex gap-2"
            >
              Submit
              {isLoading && <LoadingButton />}
            </button>
            {/* {!isFormValid && (
              <div className="self-center"> Please Fill First Name and Last Name Fields are required</div>
            )} */}
          </div>
        </FieldSectionCard>
      </form>
    </div>
  );
};

export default MiscellaneousNewCustomerForm;
