import React from "react";
import FieldSectionCard from "../../FieldSectionCard";
import FormField from "../../FormField";
import { useGetQuery, usePostMutation } from "../../../redux/apis";
import moment from "moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingSpinner from "../../LoadingSpinner";
import { macRegex } from "../../../Constants/regex";
import { ispGetModemReplacement } from "../../../utility/ispManagerPrams";

const ReplaceModem: React.FC = () => {
  const { customer } = useSelector((state: any) => state.customer);
  const { data, isLoading } = useGetQuery({
    actionName: "getmodemModelWest",
    transactionData: "",
  });

  const modemDropDown: any = Object.entries(data || {}).map(
    ([key, value]: any) => ({
      value: value,
      label: key,
    })
  );
  const [swap, { isLoading: postLoading }] = usePostMutation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const form: any = Object.fromEntries(
      new FormData(e.target as HTMLFormElement).entries()
    );
    const { person, modem, serviceType, address, technologyType }: any =
      customer;
    const transactionData = {
      modemSwapDate: moment(form.modemSwapData).format("MM/DD/YYYY HH:mm:ss"),
      createdBy: "joshua@ciphertv.com",
      transactionType: "Replace Modem",
      newModem: {
        modemModel: modemDropDown.find(
          (find: any) => find.value === form.modemModel
        ).label,
        modemMac: form.modemMac,
        modemEsn: form.modemEsn,
        maxDownLink: form.modemModel,
      },
      person: { ...person, beCode: "WE12", tpiaName: "AEBC" },
      oldModem: modem,
      serviceType,
      address,
      technologyType,
    };
    try {
      const { data } = await swap({
        actionName: "servicerequests_modemReplacement",
        transactionData,
        ispmgr: ispGetModemReplacement(transactionData),
      });

      toast.info(
        data?.response ? `${data?.response}, ${data?.trxId}` : data?.responseMsg
      );
    } catch (e) {
      toast.info("Error");
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="p-4">
      <FieldSectionCard title="Replace Modem" columns={1}>
        <form onSubmit={handleSubmit} className="col-span-10">
          <FieldSectionCard title="New Modem Details" columns={2}>
            <FormField
              label="Modem Model"
              type="select"
              name="modemModel"
              options={modemDropDown}
              required
            />
            <FormField
              label="Modem ESN *"
              type="text"
              name="modemEsn"
              placeholder="Modem ESN"
              required
            />
            <FormField
              label="Modem MAC *"
              type="text"
              name="modemMac"
              placeholder="Modem MAC"
              required
              pattern={macRegex.regex}
              title={macRegex.title}
            />
            <FormField
              label="Modem Swap Date"
              type="date"
              name="modemSwapDate"
              required
            />
          </FieldSectionCard>
          <FormField
            label=""
            type="submit"
            buttonLabel="Submit"
            loading={postLoading}
            disable={postLoading}
          />
        </form>
      </FieldSectionCard>
    </div>
  );
};

export default ReplaceModem;
