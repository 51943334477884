import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TransactionState {
  currentTransaction: any | null;
  searchTransactionsList: any | null;
}

const initialState: TransactionState = {
  currentTransaction: null,
  searchTransactionsList: null,
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setCurrentTransaction(state, action: PayloadAction<any>) {
      state.currentTransaction = action.payload;
    },
    setsearchTransactionsList(state, action: PayloadAction<any>) {
      state.searchTransactionsList = action.payload;
    },
    setCurrentTransactionHostory(state, action: PayloadAction<any>) {
      state.currentTransaction = {
        ...state.currentTransaction,
        history: action.payload,
      };
    },
  },
});

export const {
  setCurrentTransaction,
  setCurrentTransactionHostory,
  setsearchTransactionsList,
} = transactionSlice.actions;

export default transactionSlice.reducer;
