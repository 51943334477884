import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import CustomerDetails from "./pages/CustomerDetails";
import ViewTransaction from "./components/HomeScreen/ViewTransaction";
import NewCustomer from "./pages/NewCustomer";
import NotFound from "./pages/NotFound";
import ChangeSpeedTier from "./components/CustomerDetails/CustomerSupport/ChangeSpeedTier";
import AccountInformation from "./components/CustomerDetails/CustomerSupport/AccountInfomation";
import Disconnection from "./components/CustomerDetails/CustomerSupport/Disconnection";
import Reconnection from "./components/CustomerDetails/CustomerSupport/Reconnection";
import Reschedule from "./components/CustomerDetails/CustomerSupport/Reschedule";
import ReplaceModem from "./components/CustomerDetails/CustomerSupport/ReplaceModem";
import RelocateOutlet from "./components/CustomerDetails/CustomerSupport/RelocateOutlet";
import TransactionsList from "./components/CustomerDetails/CustomerSupport/TransactionsList";
import MoveCustomer from "./components/CustomerDetails/CustomerSupport/MoveCustomer";
import ModemDiagnosticInformation from "./components/CustomerDetails/Troubleshooting/ModemDiagnosticInformation";
import RFTroubleshooting from "./components/CustomerDetails/Troubleshooting/RFTroubleshooting";
import SlowSpeedTroubleshooting from "./components/CustomerDetails/Troubleshooting/SlowSpeedTroubleshooting";
import ProvisioningIssue from "./components/CustomerDetails/Troubleshooting/ProvisioningIssue";
import DHCPTroubleshooting from "./components/CustomerDetails/Troubleshooting/DHCPTroubleshooting";
import ServiceRoutingIssue from "./components/CustomerDetails/Troubleshooting/ServiceRoutingIssue";
import Miscellaneous from "./pages/Miscellaneous";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/search-criteria" element={<Home />} />
        <Route path="/miscellaneous" element={<Miscellaneous />} />
        <Route path="/create-customer" element={<NewCustomer />} />
        <Route path="/existing-customer" element={<CustomerDetails />}>
          <Route index element={<AccountInformation />} />
          <Route path="changeSpeedTier" element={<ChangeSpeedTier />} />
          <Route path="disconnect" element={<Disconnection />} />
          <Route path="reconnect" element={<Reconnection />} />
          <Route path="reschedule" element={<Reschedule />} />
          <Route path="replaceModem" element={<ReplaceModem />} />
          <Route path="relocateOutlet" element={<RelocateOutlet />} />
          <Route path="transactions" element={<TransactionsList />} />
          <Route path="moveCustomer" element={<MoveCustomer />} />
          <Route
            path="modemDiagnosticInformation"
            element={<ModemDiagnosticInformation isMainScreen={true} />}
          />
          <Route path="rfTroubleshooting" element={<RFTroubleshooting />} />
          <Route
            path="slowSpeedTroubleshooting"
            element={<SlowSpeedTroubleshooting />}
          />
          <Route path="provisioningIssue" element={<ProvisioningIssue />} />
          <Route path="dhcpTroubleshooting" element={<DHCPTroubleshooting />} />
          <Route
            path="serviceRoutingIssues"
            element={<ServiceRoutingIssue />}
          />
        </Route>

        <Route path="/transaction/:id" element={<ViewTransaction />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
