import React from "react";
import SearchCriteria from "../components/HomeScreen/SearchCriteria";
// import Notifications from "../components/HomeScreen/Notification";

const Home: React.FC = () => {
  return (
    <div className="flex flex-col h-screen">
      <main className="flex-grow px-5 lg:px-20 md:px-5 sm:px-10">
        <SearchCriteria />
        {/* <GetListOfServices /> */}
      </main>
    </div>
  );
};

export default Home;
