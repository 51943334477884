import React from "react";

const Radio = ({ label, name, required }) => {
  return (
    <div className="space-y-1 mb-4">
      <label className="font-semibold">{label}</label>
      <div className="flex items-center mb-4 gap-1">
        <input type="radio" value="yes" name={name} required={required} />
        <label>Yes</label>
        <input type="radio" value="no" name={name} required={required} />
        <label>No</label>
      </div>
    </div>
  );
};

export default Radio;
